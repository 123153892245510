// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-static-jsx": () => import("../src/templates/static.jsx" /* webpackChunkName: "component---src-templates-static-jsx" */),
  "component---src-templates-video-jsx": () => import("../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */),
  "component---src-templates-article-jsx": () => import("../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-festival-article-jsx": () => import("../src/templates/festival-article.jsx" /* webpackChunkName: "component---src-templates-festival-article-jsx" */),
  "component---src-templates-festival-jsx": () => import("../src/templates/festival.jsx" /* webpackChunkName: "component---src-templates-festival-jsx" */),
  "component---src-templates-location-jsx": () => import("../src/templates/location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */),
  "component---src-templates-information-jsx": () => import("../src/templates/information.jsx" /* webpackChunkName: "component---src-templates-information-jsx" */),
  "component---src-templates-transport-jsx": () => import("../src/templates/transport.jsx" /* webpackChunkName: "component---src-templates-transport-jsx" */),
  "component---src-templates-tickets-jsx": () => import("../src/templates/tickets.jsx" /* webpackChunkName: "component---src-templates-tickets-jsx" */),
  "component---src-templates-faq-jsx": () => import("../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-partner-jsx": () => import("../src/templates/partner.jsx" /* webpackChunkName: "component---src-templates-partner-jsx" */),
  "component---src-templates-artist-jsx": () => import("../src/templates/artist.jsx" /* webpackChunkName: "component---src-templates-artist-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-festivals-index-en-au-jsx": () => import("../src/pages/festivals/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-festivals-index-en-au-jsx" */),
  "component---src-pages-festivals-index-jsx": () => import("../src/pages/festivals/index.jsx" /* webpackChunkName: "component---src-pages-festivals-index-jsx" */),
  "component---src-pages-index-en-au-jsx": () => import("../src/pages/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-index-en-au-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lifestyle-index-en-au-jsx": () => import("../src/pages/lifestyle/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-lifestyle-index-en-au-jsx" */),
  "component---src-pages-lifestyle-index-jsx": () => import("../src/pages/lifestyle/index.jsx" /* webpackChunkName: "component---src-pages-lifestyle-index-jsx" */),
  "component---src-pages-music-index-en-au-jsx": () => import("../src/pages/music/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-music-index-en-au-jsx" */),
  "component---src-pages-music-index-jsx": () => import("../src/pages/music/index.jsx" /* webpackChunkName: "component---src-pages-music-index-jsx" */),
  "component---src-pages-search-index-en-au-jsx": () => import("../src/pages/search/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-search-index-en-au-jsx" */),
  "component---src-pages-search-index-jsx": () => import("../src/pages/search/index.jsx" /* webpackChunkName: "component---src-pages-search-index-jsx" */),
  "component---src-pages-surfing-index-en-au-jsx": () => import("../src/pages/surfing/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-surfing-index-en-au-jsx" */),
  "component---src-pages-surfing-index-jsx": () => import("../src/pages/surfing/index.jsx" /* webpackChunkName: "component---src-pages-surfing-index-jsx" */),
  "component---src-pages-tv-index-en-au-jsx": () => import("../src/pages/tv/index.en-AU.jsx" /* webpackChunkName: "component---src-pages-tv-index-en-au-jsx" */),
  "component---src-pages-tv-index-jsx": () => import("../src/pages/tv/index.jsx" /* webpackChunkName: "component---src-pages-tv-index-jsx" */)
}

